import React from "react";
import "./RecycleBin.css"
import RecycleBackground from "../../Images/recycle.png"

const RecycleBin = () => {
  return (
    <>
      <div className="backgroundRecycle">
      <img className="imagePosition" src={RecycleBackground} alt="Recycle Background" />

        <h1 className="textcolorRecycle">Leave here your worries, negative thoughts and everything that makes you sad, we will recycle them :)</h1>
      </div>
    </>
  );
};

export default RecycleBin;