import React from 'react'
import './Common.css'
import { NavLink } from 'react-router-dom'
import Draggable from 'react-draggable'
import { BrowserView, MobileView } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { ContentAbout } from '../pages/Content'
// import PDFViewer from 'pdf-viewer-reactjs'
// import { Document } from "react-pdf";

const About = () => {
  return (
    <>
      <BrowserView>
        <Draggable>
          <div>
            <Helmet>
              <title>About Me Page</title>
              <meta
                name='Dmitri Samoteev About Me page'
                content='Fullstack developer, React, Gatsby, Lua, Love2d, MaterialUI, CSS, Bootstrap'
                data-react-helmet='true'
              ></meta>
            </Helmet>
            <div class='window'>
              <div class='inner'>
                <div class='header'>
                  <NavLink to='/'>
                    <button className='closeButton'>
                      <b>X</b>
                    </button>
                  </NavLink>
                  Post - DmitriPad - About Page
                </div>
                <div className='actions'>File Edit View Run Help</div>
                <div className='content'>
                  <div className='inner'>
                    <ContentAbout />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </BrowserView>

      <MobileView>
        <div>
          <div className='windowMobile window'>
            <div className='inner'>
              <div className='header'>
                <NavLink to='/'>
                  <button className='closeButton'>
                    <b>X</b>
                  </button>
                </NavLink>
                Post - DmitriPad - About Page
              </div>
              <div className='actions'>File Edit View Run Help</div>
              <div className='content content_2'>
                <div className='inner'>
                  <ContentAbout />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}

export default About
