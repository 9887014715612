import { useEffect, useState } from 'react'

import React from 'react'

function WeatherWidget() {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [locations, setLocations] = useState('Stockholm')
  const [click, setClick] = useState()
  const [temp, setTemp] = useState()
  const [degrees, setDegrees] = useState(true)

  useEffect(() => {
    async function fetchData() {
      // You can await here

      await fetch(
        `https://api.weatherapi.com/v1/current.json?key=f1d445cc67e2496a8be133433221805&q=${locations}&aqi=nos`
      )
        // .then((response) => response.json())
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error()
          }
        })
        .then(setData)
        .catch(setError)

        .finally(() => setLoading(false))
    }

    fetchData()
  }, [locations])

  useEffect(() => {
    data && setTemp(data.current.temp_c)
  }, [data])

  const settingDegress = () => {
    degrees ? setTemp(data.current.temp_f) : setTemp(data.current.temp_c)
    setDegrees(!degrees)
  }

  const handleInputChange = (event) => {
    setClick(event.target.value)
  }
  const handleClick = (event) => {
    event.preventDefault()
    setLocations(click)
  }

  console.log(data)
  // const locationName = () => {
  //   ;<>
  //     {!data.location.name ? (
  //       setLocations('donduseni')
  //     ) : (
  //       <span>
  //         {data.location.name},&nbsp;
  //         {data.location.country},&nbsp;
  //       </span>
  //     )}
  //   </>
  // }

  const arrangedData = () => {
    return (
      <div>
        <div>
          {data ? (
            <div className='main'>
              <div className='paragraph'>
                <span>
                  {data.location.name},&nbsp;
                  {data.location.country},&nbsp;
                </span>
                <span className='container-weather'>
                  {degrees ? (
                    <span>
                      {temp}&nbsp;{'C'}
                    </span>
                  ) : (
                    <span>{temp + ' F'}</span>
                  )}
                  {degrees ? (
                    <button
                      className='weatherWidgetButtons'
                      onClick={() => {
                        settingDegress()
                      }}
                    >
                      F
                    </button>
                  ) : (
                    <button
                      className='weatherWidgetButtons'
                      onClick={() => {
                        settingDegress()
                      }}
                    >
                      C
                    </button>
                  )}
                </span>
              </div>

              <div className='clear'>{data.current.condition.text} </div>
              <div>
                <img
                  className='sun'
                  src={data.current.condition.icon}
                  alt='weather condition'
                />
              </div>

              <input
                className='inputField'
                type='text'
                placeholder=' Search location...'
                onChange={handleInputChange}
              />
              {locations ? (
                <button
                  className='weatherWidgetButtons-s'
                  type='submit'
                  onClick={handleClick}
                >
                  Submit
                </button>
              ) : (
                setLocations('Chisinau')
              )}
            </div>
          ) : (
            setLocations('Chisinau')
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {loading ? (
        'loading'
      ) : error ? (
        'Error occured, try to refresh the page'
      ) : (
        <div className='weather-title'>
          <h4>Weather Widget</h4>
          {arrangedData()}
        </div>
      )}
    </>
  )
}

export default WeatherWidget
