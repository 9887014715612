import React from 'react'
import Computer from '../Images/computer.png'
import Folder from '../Images/folder.png'
import Game from '../Images/game.png'
import Network from '../Images/network.png'
import Bin from '../Images/recycling-bin.png'

import './Components.css'
import { NavLink } from 'react-router-dom'
// import Contact from "./pages/Contact";
import './pages/Contact.css'

function Components() {
  // const [showWindow, setShowWindow] = useState(false);

  return (
    <>
      <div className='iconsBox'>
        <NavLink to='/about'>
          <img className='image' src={Computer} alt='Computer Icon' />
          <p className='text_icons'>About Me</p>
        </NavLink>
        <NavLink to='/portfolio'>
          <img className='image' src={Folder} alt='Folder Icon' />
          <p className='text_icons'>My Portfolio</p>
        </NavLink>
        <NavLink to='/game'>
          <img className='image' src={Game} alt='Game Icon' />
          <p className='text_icons'> Games</p>
        </NavLink>
        <NavLink to='/contact'>
          <img className='image' src={Network} alt='Network Icon' />
          <p className='text_icons'>Contact Me</p>
        </NavLink>
        <NavLink to='/recyclebin'>
          <img className='image' src={Bin} alt='Network Icon' />
          <p className='text_icons'>Recycle Bin</p>
        </NavLink>
      </div>
    </>
  )
}

export default Components
