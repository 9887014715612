import React, { useState, useEffect, useRef } from 'react'
import './Footer.css'
import Button from 'react-bootstrap/Button'
import Volume from '../Images/volume.png'
import { NavLink } from 'react-router-dom'
import contactImage from '../Images/contact.png'
import CVdownload from '../Images/file.png'
import creditsImage from '../Images/credits.png'
import shutdownImage from '../Images/shut-down.png'
import { Helmet } from 'react-helmet'

function Footer() {
  const [popUpMenu, setPopUpMenu] = useState(false)
  const [time, setTime] = useState('Loading..')
  let startMenuRef = useRef()

  useEffect(() => {
    let handler = (event) => {
      if (!startMenuRef.current.contains(event.target)) {
        setPopUpMenu(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  setInterval(() => {
    setTime(new Date().toLocaleTimeString('sv-SE') + '')
  }, 1000)

  return (
    <div className='footer-container' id='start' ref={startMenuRef}>
      <Helmet>
        <title>Dmitri Samoteev Portfolio</title>
        <meta
          name='Dmitri Samoteev Portfolio'
          content='Junior Fullstack developer, React, Gatsby, Lua, Love2d, MaterialUI, CSS, Bootstrap'
          data-react-helmet='true'
        ></meta>
      </Helmet>
      <Button className='button' onClick={() => setPopUpMenu(!popUpMenu)}>
        Start{' '}
      </Button>
      {popUpMenu && PopUpMenu()}
      <div className='time'>
        <img className='time' src={Volume} alt='Sound Icon' />
        {time + ' '}
      </div>
    </div>
  )
}
function PopUpMenu() {
  return (
    <ul className='dropup-content'>
      <NavLink to='/contact'>
        <li className='footer_li'>
          <img className='startImage' src={contactImage} alt='contact Icon' />
          Contact Me
        </li>
      </NavLink>

      <a href='CV.pdf' target='_blank' rel='noreferrer'>
        <li className='footer_li'>
          <img className='startImage' src={CVdownload} alt='contact Icon' />
          My CV{' '}
        </li>
      </a>
      <NavLink to='/credits'>
        <li className='footer_li'>
          <img className='startImage' src={creditsImage} alt='credit Icon' />
          Credits
        </li>
      </NavLink>

      <NavLink to='/shutdown'>
        <li className='footer_li'>
          <img className='startImage' src={shutdownImage} alt='contact Icon' />
          Shut Down
        </li>
      </NavLink>
    </ul>
  )
}

export default Footer
