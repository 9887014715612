import React from 'react'
import Typewriter from 'typewriter-effect'
import { Helmet } from 'react-helmet'
import '../App.css'

function Home() {
  return (
    <div className='homeContainer'>
      <div className='type'>
        <Helmet>
          <title>Home Page</title>
          <meta
            name='Dmitri Samoteev Home page'
            content='Junior Fullstack developer, React, Gatsby, Lua, Love2d, MaterialUI, CSS, Bootstrap'
            data-react-helmet='true'
          ></meta>
        </Helmet>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString('Hello World..')
              .pauseFor(1000)
              .deleteAll()
              .typeString('Welcome to DmitriOS :)')
              .deleteAll()
              .typeString('A nostalgic place about classic OS')
              .start()
          }}
        />
      </div>
      <div className='block'>
        <p>
          * React JS * Firebase * Git * GitHub * GitLab * Lua * Löve2D * Azure *
          C# * HTML5 * CSS3 * Bootstrap * Firebase * Netlify * Heroku * Bash *
          CI/CD (GitLab CI) * Scrum * Agile Workflow * NodeJs *
        </p>
      </div>
    </div>
  )
}

export default Home
