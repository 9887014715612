import React, { useState, useEffect } from 'react'
import './Styles.css'

const GithubTrends = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    async function fetchData() {
      // You can await here

      await fetch('https://gtrend.yapie.me/')
        .then((response) => response.json())
        .then(setData)
        .catch(setError)

        .finally(() => setLoading(false))
    }

    fetchData()
  }, [])

  const dataMapping =
    data &&
    data.map((content, index) => {
      const number = index + 1
      return (
        <ul key={index}>
          <li>
            {number + '.'} {'Author: ' + content.author}
          </li>
          <p className='git-text'>{'Name: ' + content.name}</p>
          <p className='git-text'>
            <a href={content.url} target='_blank' rel='noreferrer noopener'>
              {'Description: ' + content.description}
            </a>
          </p>
          <p className='git-text'>{'Written in: ' + content.language}</p>
          <p className='git-text'>{'Stars: ' + content.stars}</p>
        </ul>
      )
    })

  //   useEffect(() => {
  //     data && setGitData(data.author)
  //   }, [data])

  return (
    <>
      {loading ? (
        'loading'
      ) : error ? (
        'Error occured, try to refresh the page'
      ) : (
        <div className='main-git'>
          <div className='github-container'>
            <h3 className='github-title'>Github trends</h3>
            <div className='data-box'>{dataMapping}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default GithubTrends
