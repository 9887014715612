import React from 'react'
import './Common.css'
// import LinkedinLogo from "../../Images/LI-Logo.png"
// import GitHubLogo from "../../Images/GitHubMark.png"
// import contactImage from "../../Images/contact.png"
// import CV from "../../Documents/CV-Dmitri-Samoteev_2021-08-09.pdf";
import { NavLink } from 'react-router-dom'
import Draggable from 'react-draggable'
import { BrowserView, MobileView } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { ContentContact } from '../pages/Content'
import ContactForm1 from '../ContactForm1'

const Contact = () => {
  return (
    <>
      <BrowserView>
        <Draggable>
          <div>
            <Helmet>
              <title>Contact Page</title>
              <meta
                name='Dmitri Samoteev Contact page'
                content='GitHub, Gitlab, Fullstack developer, React, Gatsby, Lua, Love2d, MaterialUI, CSS, Bootstrap'
                data-react-helmet='true'
              ></meta>
            </Helmet>
            <div className='window'>
              <div className='inner'>
                <div className='header'>
                  <NavLink to='/'>
                    <button className='closeButton'>
                      <b>X</b>
                    </button>
                  </NavLink>
                  Post - DmitriPad - Contact Page
                </div>
                <div class='actions'>File Edit View Run Help</div>
                <div class='content'>
                  <div class='inner'>
                    <ContentContact />
                    <ContactForm1 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </BrowserView>

      <MobileView>
        <div className='windowMobile window'>
          <div className='inner'>
            <div className='header'>
              <NavLink to='/'>
                <button className='closeButton'>
                  <b>X</b>
                </button>
              </NavLink>
              Post - DmitriPad
            </div>
            <div className='actions'>File Edit View Run Help</div>
            <div className='content'>
              <div className='inner'>
                <ContentContact />
                <ContactForm1 />
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}

export default Contact
