import React from 'react'
// import CV from "../../Documents/CVFile.pdf"
import LinkedinLogo from '../../Images/LI-Logo.png'
import GitHubLogo from '../../Images/GitHubMark.png'
import contactImage from '../../Images/contact.png'
import './Game.css'
import Norland from '../../Images/Norrland.png'
import Cardent from '../../Images/Cardent.png'
import Muzikawi from '../../Images/Maniva.png'
import GameVideo from '../../Video/GameVideo.webm'
import MonitorVideo from '../../Video/dms-system-mon-i7.webm'
import EasyGit from '../../Video/easyGitV2.webm'
import './Portfolio.css'
import './Common.css'

export const ContentAbout = () => {
  return (
    <div>
      <h3>
        <p>Welcome to my Portfolio Site.</p>
      </h3>
      <h4>
        <p>A few words about me.</p>
      </h4>
      <p>
        I am a full stack web developer, I create, maintain and develop web
        applications. I work with React, NodeJs, JavaScript, TypeScript,
        Firebase and others.
      </p>
      <p>
        I have worked in many areas during my career, owned a business, worked
        as a research engineer at the technical institute of Stockholm,
        following that, search engine evaluator at Yandex, application engineer
        in power industry, while working in these places, I gained tons of
        experience, both professional and human-interaction wise.
      </p>
      <p>
        <b>
          However, my heart was looking towards IoT all this time. At some
          moment, I've decided to switch my life, towards coding.
        </b>
      </p>
      <p>
        I have started to learn Python, did some basic programs, one of them was
        a terminal based fantasy game. Python helped me understand the basics of
        programming, variable types, functions, conditionals and so on. I have
        played around with python and my raspberry pi device as well, where I
        connected some leds to GPIO and did some tests, currently, my Rpi device
        is used as a DNS filter and home file server.{' '}
      </p>
      <p>
        After that, I was enrolled into a C# .Net course, held by Lexicon
        Stockholm. It was an extremely intensive course and being a novice, I
        had hard times keeping up the pace, I didn't give up and took everything
        I could from it, while successfully finished the course.{' '}
      </p>
      <p>
        That was the start of my path in programming, after that, followed the
        Azure certification from Microsoft, Lua and Love 2D at Udemy,
        experimenting with different frameworks and languages.
      </p>
      <p>
        Everything lead finally to the internship at ArtfulBits AB, a place
        where I gained most of my knowledge and thanks to the awesome mentor
        Oleksandr and my amazing team, my learning went fast and in an amazing
        environment.
      </p>
      <p>
        As for my personal life, it consists of my awesome family, 2 amazing
        little kids and a lovely wife, who decided to take the developer path as
        well.
      </p>
      <p>
        As hobbies, besides IoT hardware and software projects, I love the
        Swedish nature, fishing, camping, mushroom and berry picking.
      </p>

      <h4>
        <p>Why me?</p>
      </h4>
      <li>
        I love working in a team but I tend to analyze and formulate my own
        decisions.{' '}
      </li>
      <li>
        I have experience managing well my workflow, both, in an office and when
        working remotely{' '}
      </li>
      <li>
        I follow the guidelines set by the management, on the other side, I do
        not hesitate to present my own point of view if needed.{' '}
      </li>
      <li>
        Experienced in speaking in front of the customers, higher managers from
        other companies. I was making presentations and product training in
        front of 30+ people, both, personally and online.{' '}
      </li>
      <li>
        I like the job to be done, however, I am never in a rush, ignoring the
        quality.
      </li>
      <li>
        Experience with computer hardware, I can analyze the needed hardware for
        different types of tasks, or, I can distinguish a potential hardware
        issue from a software one.{' '}
      </li>
      <p>
        More detailed information is presented in my{' '}
        <a href='CV.pdf' target='_blank'>
          <b>CV</b>
        </a>
      </p>
    </div>
  )
}

export const ContentContact = () => {
  return (
    <div>
      <p>
        <b>Welcome to my Contact Page.</b>
      </p>
      <p></p>
      <div>
        <a
          href='https://www.linkedin.com/in/dmitri-samoteev/'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img className='image1' src={LinkedinLogo} alt='Linkedin Logo' />
        </a>
        <p>You can send me a message on Linkedln.</p>

        <a
          href='https://github.com/ELDevelopero/'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img className='image2' src={GitHubLogo} alt='GitHub Logo' />
        </a>
        <p>Please have a look at my GitHub page.</p>

        <img className='image2' src={contactImage} alt='contact Icon' />

        <p>You can send me an e-mail by using the form below.</p>
      </div>
    </div>
  )
}

export const ContentCredits = () => {
  return (
    <div>
      <h3>
        <p>
          Credits to the artists for the awesome icons set, used in this
          project.
        </p>
      </h3>
      <b>Games icon:</b>{' '}
      <div>
        Icons made by{' '}
        <a href='https://www.flaticon.com/authors/freepik' title='Freepik'>
          Freepik
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Folder icon:</b>{' '}
      <div>
        Icons made by{' '}
        <a href='https://www.freepik.com' title='Freepik'>
          Freepik
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Computer Icon</b>:
      <div>
        Icons made by{' '}
        <a href='https://www.freepik.com' title='Freepik'>
          Freepik
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Network Icon:</b>{' '}
      <div>
        Icons made by{' '}
        <a href='https://www.flaticon.com/authors/phatplus' title='phatplus'>
          phatplus
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Paperclip Icon:</b>
      <div>
        Icons made by{' '}
        <a href='https://www.freepik.com' title='Freepik'>
          Freepik
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Contact icon:</b>
      <div>
        Icons made by{' '}
        <a href='https://www.flaticon.com/authors/eucalyp' title='Eucalyp'>
          Eucalyp
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Credits Icon:</b>{' '}
      <div>
        Icons made by{' '}
        <a href='https://www.flaticon.com/authors/eucalyp' title='Eucalyp'>
          Eucalyp
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Recycle bin Icon:</b>
      <div>
        Icons made by{' '}
        <a href='https://smashicons.com/' title='Smashicons'>
          Smashicons
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
      <b>Shut Down Icon:</b>{' '}
      <div>
        Icons made by{' '}
        <a href='https://www.flaticon.com/authors/good-ware' title='Good Ware'>
          Good Ware
        </a>{' '}
        from{' '}
        <a href='https://www.flaticon.com/' title='Flaticon'>
          www.flaticon.com
        </a>
      </div>
    </div>
  )
}

export const ContentGame = () => {
  return (
    <div>
      <p>
        <b>Mika's Adventures.</b>
      </p>
      <p></p>
      <div>
        <h5>
          <p>A platformer game created with Lua and Love2d framework.</p>
        </h5>
        <p></p>
        <h5>
          <p>Click on the picture and the game will open in a new tab.</p>
        </h5>
        <p></p>
        <h5>
          <p>
            Please note, since the game is in demo mode, it is not playable on
            mobile devices yet. Enjoy :)
          </p>
        </h5>
        <p></p>
        <a
          href='http://mikasadventures.000webhostapp.com/'
          target='_blank'
          rel='noreferrer noopener'
        >
          <video
            className='imagePortfolio'
            src={GameVideo}
            alt='Game'
            autoPlay
            loop
            muted
          />
        </a>
      </div>
    </div>
  )
}

export const ContentPortfolio = () => {
  return (
    <div>
      <h3>
        <p>
          <b>Welcome to my Portfolio Page</b>
        </p>
      </h3>
      <h4>
        <p>Some of the recent projects are below.</p>
      </h4>

      <h5>
        <p>
          Click on the picture to open the project page or website in a new tab.
        </p>
      </h5>
      <h5>
        <a
          href='https://github.com/ELDevelopero/easy-git'
          target='_blank'
          rel='noreferrer noopener'
        >
          <video
            className='videoPortfolioTall'
            src={EasyGit}
            alt='App'
            autoPlay
            loop
            muted
          />
        </a>
        <h5>
          DMS Easy Git is a desktop application to replace the CLI with a
          graphic interface, I have created it for my wife as she wanted
          something easy and quick, in order to avoid typing in the terminal the
          common commands, over and over again. In the above picture is the
          second, more advanced version of the application whilst the first
          version is more basic.{' '}
        </h5>
        <h5>
          Update: Added the clock(waiting) emoji so the user can see that some
          work is done behind the scenes and there's no need to press the button
          several times.
        </h5>

        <h5>
          Technologies used:
          <li>NodeJS</li>
          <li>NodeGui library</li>
          <li>
            <a
              href='https://github.com/ELDevelopero/easy-git'
              target='_blank'
              rel='noreferrer noopener'
            >
              GitHub link to the source code.
            </a>
          </li>
        </h5>
      </h5>

      <h5>
        <a
          href='https://github.com/ELDevelopero/dms-system-monitor'
          target='_blank'
          rel='noreferrer noopener'
        >
          <video
            className='videoPortfolioTall'
            src={MonitorVideo}
            alt='App'
            autoPlay
            loop
            muted
          />
        </a>
        <h5>
          DMS System Monitor is a desktop application to monitor the system
          resources. The idea came up when I was trying to find a monitor to
          show me the frequency of the processor and not the load in percentage,
          to not be too intrusive into the workspace either. I needed to see at
          what moment the processor goes overcklocked. While HTOP and others are
          awesome monitors, they did not fit my needs entirely. Some were
          displaying too much information, others too little. So, a couple of
          evenings and I've built my own :).
        </h5>
        <h5>
          Update: Added the clear Ram and clean disk buttons, they clear cache
          and unused kernel modules in linux.
        </h5>
        <h5>
          Technologies used:
          <li>NodeJS</li>
          <li>NodeGui library</li>
          <li>
            <a
              href='https://github.com/ELDevelopero/dms-system-monitor'
              target='_blank'
              rel='noreferrer noopener'
            >
              GitHub link to the source code.
            </a>
          </li>
          <li>
            <a
              href='https://drive.google.com/drive/folders/1Y_MxUc93s-d4SvY6duUflN5GOEAUq-zU?usp=sharing'
              target='_blank'
              rel='noreferrer noopener'
            >
              Linux appImage can be downloaded here.
            </a>
          </li>
        </h5>
      </h5>
      <a
        href='https://norlandservice.se/'
        target='_blank'
        rel='noreferrer noopener'
      >
        <img className='imagePortfolio' src={Norland} alt='Norland' />
        <p></p>
      </a>
      <h5>
        Norland Service is a project for a small company where me and my team,
        created the website from scratch, both design and development.
      </h5>
      <h5>
        Technologies used:
        <li>React with Gatsby framework</li>
        <li>Material UI</li>
        <li>Jest testing</li>
        <li>Netlify deployment service</li>
        <li>Google Firebase</li>
      </h5>
      <p></p>
      <a href='https://cardent.md/' target='_blank' rel='noreferrer noopener'>
        <img className='imagePortfolio' src={Cardent} alt='Cardent' />
      </a>
      <h5>
        Cardent, is a car service business, the project was created by me,
        together with my wife.
      </h5>
      <h5>
        Technologies used:
        <li>React with Gatsby framework</li>
        <li>Material UI</li>
        <li>Netlify deployment service</li>
      </h5>
      <p></p>
      <a
        href='http://mikasadventures.000webhostapp.com/'
        target='_blank'
        rel='noreferrer noopener'
      >
        <video
          className='imagePortfolio'
          src={GameVideo}
          alt='Game'
          autoPlay
          loop
          muted
        />
      </a>
      <h5>
        Mika's Adventures is a platformer game, created as a hobby project. This
        is a demo and the game is still under heavy development, many features
        are deactivated due to the bugs. As soon as it is ready, I'll make it
        open for the open-source community.
      </h5>
      <h5>
        Technologies used:
        <li>Lua and Love2d framework.</li>
        <li>Anim8 library.</li>
        <li>Hump library.</li>
        <li>Windfield library.</li>
        <li>
          <a
            href='https://github.com/ELDevelopero/platformer'
            target='_blank'
            rel='noreferrer noopener'
          >
            GitHub link to the game.
          </a>
        </li>
      </h5>
      <p></p>
      <a
        href='https://61374cae512f0f8e04178667--upbeat-khorana-f3a26a.netlify.app/'
        target='_blank'
        rel='noreferrer noopener'
      >
        <img className='imagePortfolio' src={Muzikawi} alt='Muzikawi' />
      </a>
      <h5>
        Muzikawi is a project which me and my team were working on, what made it
        interesting, was that the design of the site was already done, and we
        needed to implement it exactly as given, pixel by pixel.
      </h5>
      <h5>
        Technologies used:
        <li>React with Gatsby framework</li>
        <li>Material UI</li>
        <li>Jest testing</li>
        <li>Netlify deployment service</li>
        <li>Google Firebase</li>
      </h5>
    </div>
  )
}
