import React from "react";

import { NavLink } from "react-router-dom";
import Draggable from "react-draggable";
import "./Portfolio.css";
import "./Common.css";
import { BrowserView, MobileView } from "react-device-detect";
import { Helmet } from "react-helmet";
import { ContentPortfolio } from "./Content";

const Portfolio = () => {
  return (
    <>
    <BrowserView>
    <Draggable>
    <div>
    <Helmet>
        <title>Portfolio Page</title>
        <meta name="Dmitri Samoteev Portfolio page" content="Junior Fullstack developer, React, Gatsby, Lua, Love2d, MaterialUI, CSS, Bootstrap" data-react-helmet="true"></meta>
      </Helmet>
    <div className="container">
      <div class="window">
        <div class="inner">
          <div class="header">
          <NavLink to="/">
            <button className="closeButton" ><b>X</b></button>
            </NavLink>
            Post - DmitriPad - Portfolio Page
          </div>
          <div class="actions">File Edit View Run Help</div>
          <div class="content">
            <div class="inner">
              <ContentPortfolio/>
                
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </Draggable>
      </BrowserView>
      <MobileView>
      <div className="container">
      <div className="windowMobile window">
        <div className="inner">
          <div className="header">
          <NavLink to="/">
            <button className="closeButton" ><b>X</b></button>
            </NavLink>
            Post - DmitriPad - Portfolio Page
          </div>
          <div className="actions">File Edit View Run Help</div>
          <div className="content">
            <div className="inner">
            <ContentPortfolio/>
            </div>
          </div>
        </div>
      </div>
      </div>
      </MobileView>
          </>
  );
};

export default Portfolio;
