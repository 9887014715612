import React from "react";
import "./404.css"
import { NavLink } from "react-router-dom";

const ErrorPage = () => {
  return (
    <>
      <div className="windowError">
        <p className="textcolorBlue">A problem has been detected that triggered this page.</p>
        <p className="textcolorBlue">The problem can be caused by the wrong URL</p>
        <p className="textcolorBlue">To solve the problem, please check the URL entered, try to refresh the page or press 
        <NavLink to="/"> here. </NavLink></p>
        <p className="textcolorBlue">Technical Information:</p>
        <p className="textcolorBlue">Error type 404</p>
      </div>
    </>
  );
};

export default ErrorPage;
