import React from 'react'
import './ShutDown.css'

const ShutDownPage = () => {
  return (
    <>
      <div className='backgroundShutDown'>
        <h1 className='textcolor'>It's now safe to leave this page.</h1>
      </div>
    </>
  )
}

export default ShutDownPage
