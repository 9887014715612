import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/Home'
import Footer from './components/Footer'
import './components/Styles.css'
// import "./App.css";
import ErrorPage from './components/pages/404'
import Contact from './components/pages/Contact'
import Components from './components/Components'
import Portfolio from './components/pages/Portfolio'
import About from './components/pages/About'
import Game from './components/pages/Game'
import ShutDownPage from './components/pages/ShutDown'
import Credits from './components/pages/Credits'
import RecycleBin from './components/pages/RecycleBin'
import { Helmet } from 'react-helmet'
import WeatherWidget from './components/WeatherWidget'
import GithubTrends from './components/GithubTrends'

function App() {
  return (
    <div className='appContainer'>
      <div className='background'>
        <Helmet>
          <title>Dmitri Samoteev Portfolio WebSite</title>
          <meta
            name='Portfolio Website'
            content='Dmitri Samoteev full stack junior developer Stockholm'
          />
        </Helmet>
        <div className='weatherWidget'>
          <WeatherWidget />
        </div>
        <div>
          <GithubTrends />
        </div>
        <Router>
          <Components />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/Contact' exact component={Contact} />
            <Route path='/portfolio' exact component={Portfolio} />
            <Route path='/About' exact component={About} />
            <Route path='/Game' exact component={Game} />
            <Route path='/ShutDown' exact component={ShutDownPage} />
            <Route path='/Credits' exact component={Credits} />
            <Route path='/RecycleBin' exact component={RecycleBin} />
            <Route component={ErrorPage} />
          </Switch>

          <Footer />
        </Router>
      </div>
    </div>
  )
}

export default App
